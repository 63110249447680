import { graphql, useStaticQuery } from "gatsby"

const usePosts = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fileAbsolutePath: { glob: "**/blog/posts/**/*.mdx" }
          frontmatter: { draft: { eq: false } }
        }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            draft
            title
            slug
            date
            featured_image {
              sharp: childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
          excerpt
        }
      }
    }
  `)

  return data.allMdx.nodes.map(post => ({
    draft: post.frontmatter.draft,
    title: post.frontmatter.title,
    slug: post.frontmatter.slug,
    date: post.frontmatter.date,
    excerpt: post.excerpt,
    featuredImage: post.frontmatter.featured_image.sharp.gatsbyImageData,
  }))
}

export default usePosts
