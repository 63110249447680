import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Title from "../title"
import * as styles from "./post-preview.module.scss"

export default function PostPreview({ post }) {
  return (
    <article>
      <Link to={`/blog/${post.slug}`} className={styles.post}>
        <GatsbyImage image={post.featuredImage} alt={post.title} className={styles.cover} />
        <div className={styles.content}>
          <Title headingLevel="h3">{post.title}</Title>
          <p>{post.excerpt}</p>
          <span>See more »</span>
        </div>
      </Link>
    </article>
  );
}
