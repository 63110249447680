import React from "react"
import usePosts from "../../hooks/use-posts"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import PostPreview from "../../components/post-preview"
import Section from "../../components/section"
import * as styles from "./blog.module.scss"

export default function BlogPage() {
  const posts = usePosts()
  return (
    <>
      <Seo title="Blog" />
      <Layout showHeaderCtaButton>
        <Section>
          <div className={styles.container}>
            {posts.length > 0
              ? posts.map(post => <PostPreview key={post.slug} post={post} />)
              : "No posts scheduled for takeoff"}
          </div>
        </Section>
      </Layout>
    </>
  )
}
